import React from "react"
import Layout from "../../components/layout"
import { graphql, Link } from "gatsby"
import CityInfrastructureCard from "./components/card"
import { InfrastructuresMap } from "../../components/map"

import styles from "./infrastructures.module.scss"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const CityHousesPage = ({ data, location }) => {
  const city = data.contentfulCity
  const places = city.infrastructures || []

  const title = "Інфраструктура " + city.name
  const description = `${places.map(place => place.name).join(", ")}`

  return (
    <Layout
      contentClassName="layout-content"
      title={title}
      description={description}
      thumbnail={city.thumbnail && city.thumbnail.fluid.src}
      location={location}
    >
      <h1>
        Інфраструктура:{" "}
        <Link to={"/" + city.slug} title={`Будинки ${city.name}`}>
          {city.name}
        </Link>
      </h1>
      <div className={styles.cardList}>
        {city.infrastructures.map(place => (
          <CityInfrastructureCard
            key={place.name}
            place={place}
            title={
              place.description &&
              place.description.json &&
              place.description.json.content[0] &&
              place.description.json.content[0].content[0] &&
              place.description.json.content[0].content[0].value
            }
          />
        ))}
      </div>
      <h2>На карті:</h2>
      <InfrastructuresMap places={places} />
      <br />
      {city.description ? (
        <div className="description-text">
          {documentToReactComponents(city.description.json)}
        </div>
      ) : null}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulCity(slug: { eq: $slug }) {
      slug
      name
      description {
        json
      }
      location {
        lat
        lon
      }
      thumbnail {
        fluid(maxWidth: 1200, maxHeight: 900, cropFocus: CENTER) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
      infrastructures {
        name
        location {
          lat
          lon
        }
        description {
          json
        }
        photo {
          fluid(maxWidth: 600, maxHeight: 400, cropFocus: CENTER) {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  }
`

export default CityHousesPage
