import React from "react"
import Img from "gatsby-image/index"

import styles from "./card.module.scss"

const CityInfrastructureCard = ({ place, title }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardImageWrapper}>
        {place.photo ? (
          <Img
            fluid={place.photo.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            className={styles.thumbnail}
            title={title}
            alt={place.name}
          />
        ) : null}
      </div>
      <h3 className={styles.title}>{place.name}</h3>
    </div>
  )
}

export default CityInfrastructureCard
